<template>
    <project-invoice-report
        report_name="Cashflow Report"
        :report_query="getCashflowReport"
    />
</template>

<script>
import ProjectInvoiceReport from '@/pages/reports/components/ProjectInvoiceReport';
import {getCashflowReport} from '@/api/reports';

export default {
    name: 'report-cashflow',
    methods: {getCashflowReport},
    components: {
        ProjectInvoiceReport,
    },
};
</script>

<template>
    <project-invoice-report
        report_name="Profit & Loss Report"
        :report_query="getProfitLossReport"
    />
</template>

<script>
import ProjectInvoiceReport from '@/pages/reports/components/ProjectInvoiceReport';
import {getProfitLossReport} from '@/api/reports';

export default {
    name: 'report-cashflow',
    methods: {getProfitLossReport},
    components: {
        ProjectInvoiceReport,
    },
};
</script>
